import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAppState } from "../../context/AppStateContext";
import { ActionTypes } from "../../reducer/actionTypes";
import LanguageSelector from "./LanguageSelector";

//Icons
import {
  Plus,
  NotebookPen,
  Volume2,
  VolumeX,
  TextCursorInput,
} from "lucide-react";

import {
  callAwaitStart,
  fetchLanguages,
  GetLocationDetails,
  GetMyDocuments,
  getSessionID,
  postLanguage,
  validateToken,
} from "../../api/_request";
import { useAuth } from "../../auth";

import Cookies from "js-cookie";
import SettingsOptions from "./SettingsOptions";
import { useLanguage } from "../../context/LanguageContext";
import {
  hexToHSL,
  isEmpty,
  samFacesStaticList,
  stellaFacesStaticList,
  STTOptions,
} from "../../lib/utils";

import { Helmet } from "react-helmet";
import { Skeleton } from "../skeleton/skeleton";
import { jwtDecode } from "jwt-decode";

const StateHandler = () => {
  const { state, dispatch } = useAppState();
  const { currentUser, setCurrentUser, isLoggedIn } = useAuth();

  const { translate, setLanguage } = useLanguage();
  const location = useLocation();
  const search = location.search;
  const navigate = useNavigate();

  const searchBarParams = new URLSearchParams(search);
  const token_url = searchBarParams.get("token");
  const themeValue =
    searchBarParams.get("appTheme") ||
    localStorage.getItem("stellaTheme") ||
    "light";

  const cookie_token = Cookies.get("authToken");
  const cookie_jwtData = Cookies.get("authDataJwt");

  const cachedLanguages = sessionStorage.getItem("languagesList");
  const prevSelectedAvatar = sessionStorage.getItem("stellaAvatar");
  const prevSelectedLang = sessionStorage.getItem("stellaLanguage");
  const sttLanguageStatus = localStorage.getItem("stellaSTTLanguage");
  const savedAuthToken = localStorage.getItem("Authorization");

  const [hasTokenValidationRun, setHasTokenValidationRun] =
    useState<boolean>(false);

  const [openFacesDrawer, setOpenFacesDrawer] = useState<boolean>(false);

  const [facesList, setFacesList] = useState<null | any[]>(null);


  //Use Effects That run once
  useEffect(() => {
    if (
      sttLanguageStatus == null ||
      sttLanguageStatus == undefined ||
      sttLanguageStatus == ""
    ) {
      dispatch({
        type: ActionTypes.SET_STT_LANGUAGE,
        payload: STTOptions[0]?.name,
      });
      localStorage.setItem("stellaSTTLanguage", JSON.stringify(STTOptions[0]));
    } else {
      dispatch({
        type: ActionTypes.SET_STT_LANGUAGE,
        payload: JSON.parse(sttLanguageStatus).name,
      });
    }

    CheckAuthorization();
  }, []);

  useEffect(() => {
    // Run initial setup
    if (currentUser) {
      dispatch({ type: ActionTypes.USER_LOGIN_STATUS, payload: true });
      dispatch({ type: ActionTypes.SET_USER_ID, payload: currentUser.UserId });
      dispatch({
        type: ActionTypes.SET_USER_EMAIL,
        payload: currentUser.Emailid,
      });
      dispatch({
        type: ActionTypes.SET_AUTH_TOKEN,
        payload: localStorage.getItem("Authorization")?.split(" ")[1],
      });
      dispatch({
        type: ActionTypes.SET_USER_LOCATION,
        payload: currentUser?.Country,
      });
    }

    if (state.sessionID == "" && state.isUserLoggedIn) {
      handleGetSessionID(); // Handles session setup async
    }
  }, [state.isUserLoggedIn]);

  useEffect(() => {
    /*
    If there is no previously selected face stored in localStorage:
      - Set the first item from list of faces in localstorage
      - Set the first item from list of faces in central state

    Else extract the previously set face from localstorage and set it in central state
    */

    handlePreSelectedTheme();
    dispatch({
      type: ActionTypes.SET_APP_THEME,
      payload: themeValue,
    });
  }, [state?.userLocation, currentUser?.Country]);

  //Check for previously selected language
  useEffect(() => {
    if (
      cachedLanguages &&
      state?.selectedLanguage !== null &&
      state?.selectedLanguage !== undefined &&
      state?.selectedLanguage !== "" &&
      state.userID !== ""
    ) {
      const prevLanguageItem = JSON.parse(cachedLanguages).filter(
        (item: any) => item.name === state?.selectedLanguage
      );

      if (prevLanguageItem.length > 0) {
        postLanguage(prevLanguageItem[0]?.id, state?.userID);

        setLanguage(prevLanguageItem[0]?.lang_code);

        // dispatch({
        //   type: ActionTypes.SET_LANGUAGE_JSON,
        //   payload: JSON.parse(prevSelectedLang),
        // });
      }
    } else {
      getLanguages();
    }
  }, [cachedLanguages, state?.selectedLanguage, state.userID]);

  //Login Via Token if token available in URL
  useEffect(() => {
    if (!hasTokenValidationRun) {
      validateAndNavigate();
    }
  }, [token_url]);

  useEffect(() => {
    if (state?.authToken && currentUser && state?.myDocuments === null)
      FetchMyDocuments();
  }, [currentUser, state?.authToken, state?.myDocuments]);

  ///Functions Region Start
  const CheckAuthorization = async () => {
    if (savedAuthToken) {
      dispatch({
        type: ActionTypes.SET_AUTH_TOKEN,
        payload: savedAuthToken?.split(" ")[1],
      });
    }
  };

  const handlePreSelectedTheme = () => {
   // console.log("themeValue", themeValue)
    const htmlEl = document.getElementsByTagName("html")[0];
    localStorage.setItem("stellaTheme", themeValue);
    if (
      themeValue.toLocaleLowerCase() === "light" ||
      (themeValue.toLocaleLowerCase() === "system" &&
        !window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      htmlEl.classList.remove("dark");
      htmlEl.classList.add("light");
    } else {
      htmlEl.classList.remove("light");
      htmlEl.classList.add("dark");
    }
  };

  const getLanguages = async () => {
    try {
      const res = await fetchLanguages();
      if (res) {
        // console.log("fetchLanguages RES", res);
        // Process your response here, e.g., setting state
        const { languages } = res;
        languages.forEach((language) => {
          language.lang_code = language.lang_code.replace("-", "_");
        });
        sessionStorage.setItem("languagesList", JSON.stringify(languages));

        if (
          state?.selectedLanguage === undefined ||
          state?.selectedLanguage === null ||
          state?.selectedLanguage === ""
        ) {
          // sessionStorage.setItem(
          //   "stellaLanguage",
          //   JSON.stringify(languages[0])
          // );
          dispatch({
            type: ActionTypes.SET_LANGUAGE,
            payload: languages[0].name,
          });
          dispatch({
            type: ActionTypes.SET_LANGUAGE_JSON,
            payload: languages[0],
          });
        } else {
          const prevLanguageItem = languages?.filter(
            (item: any) => item.name === state?.selectedLanguage
          );

          setLanguage(prevLanguageItem[0]?.lang_code);
          // dispatch({
          //   type: ActionTypes.SET_LANGUAGE,
          //   payload: JSON.parse(prevSelectedLang)?.name,
          // });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAwaitStart = async () => {
    try {
      const payload = {
        jsonrpc: "2.0",
        params: {
          sender_id: state.sessionID,
          message: "Hi!",
        },
      };
      const data = await callAwaitStart(payload, state.userID);
      if (data !== null) {
        if (data.length > 0) {
          // data?.map((item: any) => {
          //   dispatch({
          //     type: ActionTypes.UPDATE_CHAT_HISTORY,
          //     payload: [...state.chatHistory, [item], "loading"],
          //   });
          // });
        }
      }
    } catch (error) {
      console.error("Error getting await start:", error);
    }
  };

  const handleGetSessionID = async () => {
    const sessionBody = {
      jsonrpc: "2.0",
      params: {
        user_id: state?.userID,
      },
    };
    const sessionValue = await getSessionID(JSON.stringify(sessionBody));

    if (sessionValue) {
      if (cachedLanguages) {
        const languageList = JSON.parse(cachedLanguages);

        const selectedLang = languageList?.filter(
          (item: any) => item.name === sessionValue?.languages?.name
        );
        // console.log("selectedLang", selectedLang)

        if (selectedLang?.length > 0) {
          dispatch({
            type: ActionTypes.SET_LANGUAGE_DIRECTION,
            payload: selectedLang[0].direction,
          });
        }
      }

      //sessionStorage.setItem("stella_s_id", sessionValue.sender_id);
      //console.log("stellaLanguage", sessionValue?.languages);
      //sessionStorage.setItem("stellaLanguage", sessionValue?.languages);
      dispatch({
        type: ActionTypes.SET_SESSION_ID,
        payload: sessionValue.sender_id,
      });
      dispatch({
        type: ActionTypes.SET_LANGUAGE,
        payload: sessionValue?.languages?.name,
      });

      dispatch({
        type: ActionTypes.SET_LANGUAGE_JSON,
        payload: sessionValue?.languages?.name,
      });
    }
  };

  ///Login via Token or Cookies
  /*
    We are moving towards cookie based and token based is only temporary so that existing systems dont break
   */

  const handleJwtData = () => {
    const decodeToken = jwtDecode(cookie_jwtData!);
    dispatch({
      type: ActionTypes.SET_JWT_TOKEN,
      payload: cookie_jwtData,
    });
    dispatch({
      type: ActionTypes.SET_DECODED_JWT,
      payload: decodeToken,
    });
  };

  const validateAndNavigate = async () => {
    // if (!token_url || !cookie_token) return;

    try {
      let resp;
      if (!isEmpty(token_url) && token_url !== null) {
        //console.log("Called token_url")
        resp = await validateToken(token_url);
      } else if (!isEmpty(cookie_token) && cookie_token !== undefined) {
        handleJwtData();
        resp = await validateToken(cookie_token);
      } else {
        return;
      }
      // else if (!isEmpty(cookie_jwtData)) {
      //   handleJwtData();
      // }

      //  console.log("VALIDATE TOKEN CALLED", resp);
      if (resp?.ResponseCode === 200) {
        localStorage.setItem("Authorization", resp.data.usertoken);

        dispatch({
          type: ActionTypes.SET_AUTH_TOKEN,
          payload: resp?.data?.usertoken?.split(" ")[1],
        });

        ///Note: Might introduce the very issue we are trying to resolve in mobile

        // const prevSetCookie = Cookies.get("authToken");

        // if (prevSetCookie !== resp.data.usertoken) {

        //   //Might or might not cause issue in App PWA
        //   Cookies.set("authToken", resp.data.usertoken, {
        //     expires: 365, // Expires in 1 day. For an hour, use 1/24.
        //     path: "/",
        //     domain: ".unitedwecare.com",
        //     secure: true, // Set to true if your site is served over HTTPS
        //     sameSite: "strict", // This setting can help mitigate CSRF attacks
        //   });
        // }

        setCurrentUser(resp.data.userdetails);

        dispatch({
          type: ActionTypes.USER_LOGIN_STATUS,
          payload: true,
        });
        dispatch({
          type: ActionTypes.SET_USER_ID,
          payload: resp.data.userdetails?.UserId,
        });
        dispatch({
          type: ActionTypes.SET_USER_EMAIL,
          payload: resp.data.userdetails?.Emailid,
        });
        dispatch({
          type: ActionTypes.SET_USER_LOCATION,
          payload: resp.data.userdetails?.Country,
        });
        // const firstName = resp.data.userdetails.Name.split(" ")
        //   .slice(0, 1)
        //   .join("");
        // const now = new Date();
        // const oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));

        // document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
        // document.cookie = "userType=expert/user;";
      } else {
        dispatch({
          type: ActionTypes.USER_LOGIN_STATUS,
          payload: false,
        });
      }
      setHasTokenValidationRun(true);
    } catch (error) {
      console.error("Error validating token:", error);
    }
  };

  const handleSelectStellaFace = (
    selectedFace: string,
    initialLoad: boolean
  ) => {
    if (selectedFace !== state.selectedFace) {
      handleUpdateFaceInContext(selectedFace);

      /*INTERNATIONALIZATION*/
      const newMessage = {
        response: "update",
        type: "notification",
        message: translate("AvatarChangeMessage"),
        update: "Avatar",
      };

      /*const newMessage = {
        response: "update",
        type: "notification",
        message: 'Avatar has been changed. Hope you like it!',
        update: "Avatar",
      };*/

      dispatch({
        type: ActionTypes.UPDATE_CHAT_HISTORY,
        payload: [...state.chatHistory, newMessage],
      });
    }
  };

  const handleUpdateFaceInContext = (selectedFace: string) => {
    //Save in session storage
    sessionStorage.setItem("stellaAvatar", selectedFace);

    //Save in central state
    dispatch({
      type: ActionTypes.SET_STELLA_AVATAR,
      payload: selectedFace,
    });
  };

  const FetchMyDocuments = async () => {
    try {
      const myDocsQueries = {
        userID: currentUser?.UserId + "",
        count: 10,
        page: 1,
        token: state?.authToken,
      };
      const documentsResponse = await GetMyDocuments(myDocsQueries);

      if (documentsResponse?.status == 200) {
        dispatch({
          type: ActionTypes.SET_MY_DOCUMENTS,
          payload: documentsResponse?.data?.data,
        });
        dispatch({
          type: ActionTypes.SET_MY_DOCUMENTS_COUNT,
          payload: documentsResponse?.data?.total_count,
        });
      }
    } catch (error) {}
  };

  ///Functions Region End
  //console.log("state", state);
  // console.log("currentUser", currentUser);
  return <div></div>;
};

export default StateHandler;
